import React from 'react';

const Contact = () => (
  <section id="three">
    <h2>Get In Touch</h2>
    <p>Send me an email and I'll get back to you!</p>
    <div className="12u">
        <ul className="labeled-icons">
          <li>
            <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
            <a href="mailto: genevieve.yenyen.gen@gmail.com">genevieve.yenyen.gen@gmail.com</a>
          </li>
        </ul>
      </div>
      {/* <div className="12u">
        <form method="post" action="#">
          <div className="row uniform 50%">
            <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
            <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
            <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
          </div>
        </form>
        <ul className="actions">
          <li><input type="submit" value="Send Message" /></li>
        </ul>
      </div> */}
  </section>
)

export default Contact;
