import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner copyright">
      <p>&copy; Genevieve Rae Busby<br />2020, All Rights Reserved</p>
    </div>
  </div>
);

export default Footer;
