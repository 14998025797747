import React from 'react'
import { Link } from 'gatsby';

import Footer from './Footer'


const Header = ({ documents, projects, title, description, bg, useBg, avatar }) => {
  const style = useBg ? 
  {
    background: `url(${bg})`,
    backgroundPosition: `center`,
    backgroundSize: `auto 100%`,
  } : {};
  return (
    <header id="header" style={style}>
      <div className="inner">
        <Link to="/" className="image avatar"><img src={avatar} alt="" /></Link>
        <h1>{title}</h1>
        <p>{description}</p>
        {documents.map((doc) => (
          <div key={doc.name}>
            <Link to={`/document/${doc.name}/`} >
              {doc.title}
            </Link>
          </div>
        ))}
        <br />
        <h4>Projects</h4>
        {projects.map((project) => (
          <div  key={project.name}>
            <Link to={`/portfolio/${project.name}/`}>
              {project.title}
            </Link>
          </div>
        ))}
      </div>
      <Footer />
    </header>
  );
};

export default Header;
