import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import '../assets/scss/main.scss'

import Header from '../components/Header'
import Contact from '../components/Contact';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPageData(name: {eq: "siteMetadata"}) {
        description
        title
        avatar {
          file {
            url
          }
        }
        useBackgroundImage
        backgroundImage {
          file {
            url
          }
        }
      }
      allContentfulDocument {
        nodes {
          name
          title
        } 
      }
      allContentfulProject {
        nodes {
          name
          title
        } 
      }
    }
  `);
  const { title, description, avatar, backgroundImage, useBackgroundImage } = data.contentfulPageData;
  const documents = data.allContentfulDocument.nodes;
  const projects = data.allContentfulProject.nodes;
  return (
    <div>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: description,
          }
        ]}
      />
      <Header
        documents={documents}
        projects={projects}
        title={title}
        description={description}
        bg={backgroundImage.file.url}
        useBg={useBackgroundImage}
        avatar={avatar.file.url}
      />
      <div id="main">
        { children }
        <Contact />
      </div>
    </div>
  );
}

export default Layout;
